// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ybWG8Io_Uc8UxBl9vKib{position:relative;padding-bottom:56.25%;padding-top:25px;height:0;margin:10px 0}.ybWG8Io_Uc8UxBl9vKib iframe{position:absolute;top:0;left:0;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/YouTube/styles.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,qBAAA,CACA,gBAAA,CACA,QAAA,CACA,aAAA,CAEA,6BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA","sourcesContent":["$theme: next;\n.youtube {\n  position: relative;\n  padding-bottom: 56.25%; /* 16:9 */\n  padding-top: 25px;\n  height: 0;\n  margin: 10px 0;\n\n  iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"youtube": "ybWG8Io_Uc8UxBl9vKib"
};
export default ___CSS_LOADER_EXPORT___;
