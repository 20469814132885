import dayjs, { ManipulateType } from "dayjs";
import { unixTimestamp } from "../types/dateTypes";

type CountUnitPayload = {
  count: number;
  unit: ManipulateType;
};

export interface ICurrentUnixTimestampOptions {
  add?: CountUnitPayload;
  subtract?: CountUnitPayload;
}

/**
 * get UTC time in seconds
 *
 * @returns {number}
 */
export function currentUnixTimestamp(
  options?: ICurrentUnixTimestampOptions,
): unixTimestamp {
  const { add, subtract } = options ?? {};

  if (add) {
    const { count, unit } = add;

    return dayjs().add(count, unit).unix();
  }

  if (subtract) {
    const { count, unit } = subtract;

    return dayjs().subtract(count, unit).unix();
  }

  return dayjs().unix();
}
