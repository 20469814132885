// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QNy5NzXzWSl5SToLvPtw{position:relative;min-height:100vh;overflow:hidden}.eWZ6i8lbkShC6YSespc0{position:absolute;left:0;top:0;height:100vh;overflow-y:auto;width:100%;overflow-x:hidden}", "",{"version":3,"sources":["webpack://./../next-react/src/components/structure/MainView/components/MainViewToggleViews/styles.scss"],"names":[],"mappings":"AAIA,sBACE,iBAAA,CACA,gBAAA,CACA,eAAA,CAEA,sBACE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,YAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":["$theme: next;\n@import \"../../../../../scss/variables.scss\";\n@import \"../../../../../scss/mixins/breakpoints\";\n\n.toggleViews {\n  position: relative;\n  min-height: 100vh;\n  overflow: hidden;\n\n  &_tab {\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100vh;\n    overflow-y: auto;\n    width: 100%;\n    overflow-x: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleViews": "QNy5NzXzWSl5SToLvPtw",
	"toggleViews_tab": "eWZ6i8lbkShC6YSespc0"
};
export default ___CSS_LOADER_EXPORT___;
