// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W2ZXjCzEXQ0B6EOImnBo{display:flex;align-items:center;justify-content:center;height:100vh}", "",{"version":3,"sources":["webpack://./../next-react/src/components/structure/MainView/components/UnknownRoute/styles.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA","sourcesContent":["$theme: next;\n.unknownRoute {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unknownRoute": "W2ZXjCzEXQ0B6EOImnBo"
};
export default ___CSS_LOADER_EXPORT___;
