// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A2F3v5nguUXbh1P_jFgF{width:100%;margin:24px auto}.A2F3v5nguUXbh1P_jFgF img{max-width:100%;margin:0 auto 8px;display:block}.A2F3v5nguUXbh1P_jFgF.Wtg6WcXW2FkynjJJ3FjG,.A2F3v5nguUXbh1P_jFgF.SmMTAc1SqZ2CCFTznSXt{display:inline-block}.A2F3v5nguUXbh1P_jFgF.Wtg6WcXW2FkynjJJ3FjG{float:left;margin:0 24px 24px 0}.A2F3v5nguUXbh1P_jFgF.SmMTAc1SqZ2CCFTznSXt{float:right;margin:0 0 24px 24px}.A2F3v5nguUXbh1P_jFgF.EeIlhFSY0EipcxlhbPgV{max-width:400px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/articles/Article/components/CaptionedImage/styles.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,gBAAA,CAEA,0BACE,cAAA,CACA,iBAAA,CACA,aAAA,CAGF,sFAEE,oBAAA,CAGF,2CACE,UAAA,CACA,oBAAA,CAGF,2CACE,WAAA,CACA,oBAAA,CAGF,2CACE,eAAA","sourcesContent":["$theme: next;\n@import \"../../../../../scss/variables.scss\";\n\n.articleImage {\n  width: 100%;\n  margin: $spacing-md auto;\n\n  img {\n    max-width: 100%;\n    margin: 0 auto $spacing-sm;\n    display: block;\n  }\n\n  &.-left,\n  &.-right {\n    display: inline-block;\n  }\n\n  &.-left {\n    float: left;\n    margin: 0 $spacing-md $spacing-md 0;\n  }\n\n  &.-right {\n    float: right;\n    margin: 0 0 $spacing-md $spacing-md;\n  }\n\n  &.-small {\n    max-width: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleImage": "A2F3v5nguUXbh1P_jFgF",
	"-left": "Wtg6WcXW2FkynjJJ3FjG",
	"-right": "SmMTAc1SqZ2CCFTznSXt",
	"-small": "EeIlhFSY0EipcxlhbPgV"
};
export default ___CSS_LOADER_EXPORT___;
