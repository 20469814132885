// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wuDPH1_Vdh6OKzS99TFq h3,.wuDPH1_Vdh6OKzS99TFq label{color:#006e63 !important}", "",{"version":3,"sources":["webpack://./../next-react/src/components/views/LoginScreen/styles.scss"],"names":[],"mappings":"AAKE,qDAEE,wBAAA","sourcesContent":["$theme: next;\n@import \"../../../scss/variables\";\n\n.LoginScreen {\n  // HACK: fix to get manager app released\n  h3,\n  label {\n    color: $color-primary !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoginScreen": "wuDPH1_Vdh6OKzS99TFq"
};
export default ___CSS_LOADER_EXPORT___;
