import * as React from "react";

import { BlockButton } from "../Button";
import { HStack } from "../../structure/HStack";
import { VStack } from "../../structure/VStack";

import styles from "./styles.scss";
import { TColorVariants } from "next-shared/src/types/TColorVariants";

export interface IChoiceOption {
  label: string;
  onSelect: () => unknown;
  isSelected?: boolean;
  isDisabled?: boolean;
  shouldConfirm?: boolean;
  isHidden?: boolean;
  status?: TColorVariants;
}

export interface IChoiceProps {
  choices: IChoiceOption[];
  heading?: string;
  description?: string;
}

/**
 * Displays a choice to user: red pill or blue?
 */
export const Choice: React.FC<IChoiceProps> = ({
  choices,
  heading,
  description,
}) => {
  return (
    <div className={styles.Choice}>
      <VStack>
        {!!heading && <h4>{heading}</h4>}
        {!!description && <p>{description}</p>}
        <HStack>
          {choices
            .map((c) => {
              if (c.isHidden) return;
              return (
                <BlockButton
                  key={c.label}
                  variant={c.isSelected === false ? "secondary" : "primary"}
                  onClick={c.onSelect}
                  disabled={c.isDisabled === true}
                  shouldConfirm={c.shouldConfirm}
                  status={c.status}
                >
                  {c.label}
                </BlockButton>
              );
            })
            .filter((i) => i)}
        </HStack>
      </VStack>
    </div>
  );
};
