// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XnZa3l1XY5vFMiRbiYAw p{margin-bottom:1em}.XnZa3l1XY5vFMiRbiYAw figure img{max-width:100%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/articles/Article/components/Text/styles.scss"],"names":[],"mappings":"AAIE,wBACE,iBAAA,CAGF,iCAEE,cAAA","sourcesContent":["$theme: next;\n@import \"../../../../../scss/variables.scss\";\n\n.Text {\n  p {\n    margin-bottom: 1em;\n  }\n\n  figure img {\n    // for those put in using a wizywig\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Text": "XnZa3l1XY5vFMiRbiYAw"
};
export default ___CSS_LOADER_EXPORT___;
