// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mkzR4210t36fy_di2mLx{text-align:center}.Joov_aZGf7eciCWHYVGE{margin-bottom:10px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/SSOLogin/styles.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CAEA,sBACE,kBAAA","sourcesContent":["$theme: next;\n.SSOLogin {\n  text-align: center;\n\n  &_error {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SSOLogin": "mkzR4210t36fy_di2mLx",
	"SSOLogin_error": "Joov_aZGf7eciCWHYVGE"
};
export default ___CSS_LOADER_EXPORT___;
