// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xyic41OH6mkk9wCIxQai{height:40px;margin:.5em 0}", "",{"version":3,"sources":["webpack://./../next-react/src/components/structure/MainView/components/SideNav/components/Practitioner/styles.scss"],"names":[],"mappings":"AACA,sBACE,WAAA,CACA,aAAA","sourcesContent":["$theme: next;\n.practitioner {\n  height: 40px;\n  margin: 0.5em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"practitioner": "Xyic41OH6mkk9wCIxQai"
};
export default ___CSS_LOADER_EXPORT___;
